import { QueryClient } from '@tanstack/react-query';
import { DEVELOPMENT_MODE } from '~/services/environment/environment';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...(DEVELOPMENT_MODE && { retry: false }),
      // avoid duplicate data fetching in utils and component
      staleTime: 5_000,
    },
    mutations: {
      throwOnError: true,
    },
  },
});
