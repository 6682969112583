import { TFunction } from 'i18next';
import {
  getOutdatedReadingLabel,
  isMetricReadingOutdated,
} from '~/shared/models/metrics/metrics-utils';

export type KpiType = 'RAIN_TOTAL' | 'PLANT_USAGE' | 'WATER_STORED';

export class KpiModel {
  constructor(
    readonly type: KpiType,
    readonly value: number | undefined,
    readonly date?: Date,
  ) {}

  getLatestTimestampLabel(t: TFunction) {
    if (!this.date) {
      return null;
    }

    if (this.type !== 'WATER_STORED') {
      return null;
    }

    if (!isMetricReadingOutdated(this.date)) {
      return null;
    }

    return getOutdatedReadingLabel(t, this.date);
  }

  getLabel(t: TFunction) {
    switch (this.type) {
      case 'RAIN_TOTAL':
        return t('dashboard.kpi.rainTotalLabel');
      case 'PLANT_USAGE':
        return t('dashboard.kpi.plantUsageLabel');
      case 'WATER_STORED':
        return t('dashboard.kpi.waterStoredLabel');
      default:
        throw new Error(`Unknown KPI type: ${this.type}`);
    }
  }

  getDescription(t: TFunction) {
    switch (this.type) {
      case 'RAIN_TOTAL':
        return t('dashboard.kpi.rainTotalDescription');
      case 'PLANT_USAGE':
        return t('dashboard.kpi.plantUsageDescription');
      case 'WATER_STORED':
        return t('dashboard.kpi.waterStoredDescription');
      default:
        throw new Error(`Unknown KPI type: ${this.type}`);
    }
  }
}
