import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, Outlet } from 'react-router-dom';
import Logo from '~/pages/layout/components/Navigation/logo.png';
import LoginBackground from '~/pages/layout/login-background.png';
import { showCookiePreferences } from '~/services/cookiebar/onetrust';
import { createRoutes } from '~/shared/models/create-routes';
import { DRAWER_WIDTH } from './layout-constants';

export function AuthLayout() {
  const { t } = useTranslation();
  return (
    <Grid
      container
      minWidth="100%"
      minHeight="100vh"
      justifyContent="center"
      alignItems="center"
      p={2}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        width={DRAWER_WIDTH}
        height="auto"
      >
        <RouterLink to={createRoutes.SignIn}>
          <Box
            component="img"
            src={Logo}
            alt="Wavin logo"
            px={3}
            py={5}
            width="100%"
            height="auto"
          />
        </RouterLink>
      </Box>

      <Box
        position="fixed"
        top={(theme) => theme.spacing(4)}
        right={(theme) => theme.spacing(2)}
      >
        <Button onClick={showCookiePreferences}>
          {t('user.privacyPreferences.title')}
        </Button>
      </Box>

      <Outlet />

      <Box
        component="img"
        src={LoginBackground}
        position="absolute"
        left={0}
        bottom={0}
        width="100%"
        zIndex={-1}
      />
    </Grid>
  );
}
