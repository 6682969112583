import { ListUsersForSystemResponse_Users } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { SystemPermission_Permission } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { UserRole, RolesModel } from '~/shared/models/system/RolesModel';

export class SystemUserModel {
  readonly name: string;

  readonly email: string;

  readonly displayName: string;

  readonly permissions?: SystemPermission_Permission[];

  readonly role?: UserRole;

  constructor(user: ListUsersForSystemResponse_Users) {
    this.name = user.name;
    this.email = user.email;
    this.displayName = user.displayName;
    this.permissions = user.permission?.permissions;

    if (user.permission?.permissions) {
      this.role = RolesModel.permissionsToRole(user.permission?.permissions);
    }
  }
}
