import { Timestamp } from '@bufbuild/protobuf';
import { getReservoirDynamicInfo } from '~/pages/ReservoirDynamicInfo/api/resevoirDynamicInfo.api';
import { ReservoirDynamicInfoListModel } from '~/pages/ReservoirDynamicInfo/models/ReservoirDynamicInfoListModel';
import { makeQuery } from '~/shared/api/internal/utils';

export const getReservoirDynamicInfoQuery = (
  resourceName: string,
  timestamp?: Timestamp,
) =>
  makeQuery({
    queryKey: ['reservoirDynamicInfo', resourceName, timestamp?.toDate()],
    queryFn: async () => {
      const response = await getReservoirDynamicInfo(resourceName, timestamp);
      return new ReservoirDynamicInfoListModel(response);
    },
    refetchOnWindowFocus: false,
  });
