import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { assertIsDefined } from '~/types/assert-type';

export type DateRange = [Date, Date];

export function dateRangeEquals(a: DateRange, b: DateRange) {
  return a[0].getTime() === b[0].getTime() && a[1].getTime() === b[1].getTime();
}

export const rangeTypes = [
  'day',
  'week',
  'month',
  '3 months',
  '6 months',
  'year',
  'all',
  'custom',
] as const;

export const DEFAULT_RANGE_TYPE = 'month';
export type RangeType = (typeof rangeTypes)[number];

export function getLabel(t: TFunction, type: RangeType) {
  switch (type) {
    case 'day':
      return t('dateRangeSelector.rangeLabel.day');
    case 'week':
      return t('dateRangeSelector.rangeLabel.week');
    case 'month':
      return t('dateRangeSelector.rangeLabel.month');
    case '3 months':
      return t('dateRangeSelector.rangeLabel.3months');
    case '6 months':
      return t('dateRangeSelector.rangeLabel.6months');
    case 'year':
      return t('dateRangeSelector.rangeLabel.year');
    case 'custom':
      return t('dateRangeSelector.rangeLabel.custom');
    case 'all':
      return t('dateRangeSelector.rangeLabel.all');
    default:
      throw new Error(`Unknown range type: ${type}`);
  }
}

export function getDateRange(rangeType: RangeType): DateRange | undefined {
  const startOfDay = dayjs().startOf('day');
  switch (rangeType) {
    case 'day':
    case 'week':
    case 'month':
    case 'year':
      return [startOfDay.subtract(1, rangeType).toDate(), startOfDay.toDate()];
    case '3 months':
      return [startOfDay.subtract(3, 'month').toDate(), startOfDay.toDate()];
    case '6 months':
      return [startOfDay.subtract(6, 'month').toDate(), startOfDay.toDate()];
    case 'all':
      return [new Date(0), startOfDay.toDate()];
    default:
      return undefined;
  }
}

export function getDefaultDateRange(): DateRange {
  const result = getDateRange(DEFAULT_RANGE_TYPE);
  assertIsDefined(result);
  return result;
}
