import { SystemProductionState } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/system_pb';
import { assertUnreachable } from '~/types/assert-type';

export type SystemState =
  | 'ADVICE'
  | 'READY_FOR_INSTALL'
  | 'PRODUCTION'
  | 'PRODUCTION_EDIT'
  | 'OUT_OF_PRODUCTION';

export function productionStateToSystemState(
  productionState: SystemProductionState,
): SystemState {
  switch (productionState) {
    case SystemProductionState.ADVICE:
      return 'ADVICE';
    case SystemProductionState.READY_FOR_INSTALL:
      return 'READY_FOR_INSTALL';
    case SystemProductionState.PRODUCTION:
      return 'PRODUCTION';
    case SystemProductionState.PRODUCTION_EDIT:
      return 'PRODUCTION_EDIT';
    case SystemProductionState.OUT_OF_PRODUCTION:
      return 'OUT_OF_PRODUCTION';
    case SystemProductionState.UNSPECIFIED:
      throw new Error('Unspecified production state');
    default:
      return assertUnreachable(productionState);
  }
}

export function systemStateForApi(
  systemState: SystemState,
): SystemProductionState {
  switch (systemState) {
    case 'ADVICE':
      return SystemProductionState.ADVICE;
    case 'READY_FOR_INSTALL':
      return SystemProductionState.READY_FOR_INSTALL;
    case 'PRODUCTION':
      return SystemProductionState.PRODUCTION;
    case 'PRODUCTION_EDIT':
      return SystemProductionState.PRODUCTION_EDIT;
    case 'OUT_OF_PRODUCTION':
      return SystemProductionState.OUT_OF_PRODUCTION;
    default:
      return assertUnreachable(systemState);
  }
}
