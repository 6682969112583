import {
  Profile,
  Profile_EmailNotifications,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { TFunction } from 'i18next';

export class ProfileModel {
  readonly displayName: string;

  readonly languageCode: string;

  readonly emailNotifications: Profile_EmailNotifications;

  readonly lastAcceptedTermsAndConditions: number | undefined;

  constructor(profile: Profile) {
    this.displayName = profile.displayName;
    this.languageCode = profile.languageCode;
    this.emailNotifications = profile.emailNotifications;
    this.lastAcceptedTermsAndConditions = Number(
      profile.lastAcceptedTermsAndConditions,
    );
  }

  static getEmailNotificationLabel(
    t: TFunction,
    mode: Profile_EmailNotifications,
  ) {
    switch (mode) {
      case Profile_EmailNotifications.ENABLED:
        return t('user.emailPreferences.notifications.enabled');
      case Profile_EmailNotifications.DISABLED:
        return t('user.emailPreferences.notifications.disabled');
      case Profile_EmailNotifications.SHORT:
        return t('user.emailPreferences.notifications.short');
      default:
        throw new Error(
          `Unsupported email notification mode ${Profile_EmailNotifications[mode]}`,
        );
    }
  }
}
