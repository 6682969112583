import { Timestamp } from '@bufbuild/protobuf';
import { Severity } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/event_pb';
import {
  Issue,
  Issue_ResourceType,
  IssueStatus,
  IssueType,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/issue_pb';
import { idFromName } from '~/shared/models/id-utils';

export class IssueModel {
  readonly resourceName: string;

  readonly type: IssueType;

  readonly severity: Severity;

  readonly firstOccurrenceTime?: Timestamp;

  readonly lastOccurrenceTime?: Timestamp;

  readonly systemTitle: string;

  readonly resourceType: Issue_ResourceType;

  readonly resourceTitle: string;

  readonly systemId?: string;

  readonly status: IssueStatus;

  constructor(issue: Issue) {
    this.resourceName = issue.resourceName;
    this.type = issue.issueType;
    this.severity = issue.severity;
    this.firstOccurrenceTime = issue.firstOccurrenceTime;
    this.lastOccurrenceTime = issue.lastOccurrenceTime;
    this.systemTitle = issue.systemTitle;
    this.resourceType = issue.resourceType;
    this.status = issue.status;
    this.systemId = IssueModel.getSystemId(issue.resourceName);
    this.status = issue.status;
    this.resourceTitle = issue.resourceTitle;
  }

  private static getSystemId(resourceName: string): string | undefined {
    const systemId = IssueModel.extractSystemId(resourceName);
    return systemId ? idFromName(systemId) : undefined;
  }

  private static extractSystemId(resourceName: string): string | undefined {
    const match = resourceName.match(/^systems\/[^/]+/);
    return match ? match[0] : undefined;
  }
}
