import { SystemPermission } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { aqoraClient } from '~/services/api/aqora-client';

export const listUsers = () =>
  aqoraClient.listUsers({
    pageSize: BigInt(999999),
  });

export const linkUserToSystemsByEmail = (
  userEmail: string,
  systemPermissions: SystemPermission[],
) =>
  aqoraClient.linkUserToSystemsByEmail({
    userEmail,
    systemPermissions,
  });
