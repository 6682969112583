import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { listSystemsQuery } from '~/shared/api/system.queries';
import { checkAuthorization } from '~/shared/auth/auth-utils';
import { createDownloadsRoute } from '~/shared/models/create-routes';
import { ListSystemsModel } from '~/shared/models/system/ListSystemsModel';

export const downloadsLoader =
  (queryClient: QueryClient) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    await checkAuthorization(queryClient, request);

    const { systemId } = params;

    const listSystemsReponse =
      await queryClient.ensureQueryData(listSystemsQuery);

    const listSystemsModel = new ListSystemsModel(listSystemsReponse);

    if (!systemId) {
      const redirectUrl = createDownloadsRoute(listSystemsModel.systems[0].id);

      return redirect(redirectUrl);
    }

    return { systems: listSystemsReponse };
  };
