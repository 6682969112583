import { getAuthUser } from '~/services/api/fetchers/auth-user.api';
import { makeQuery } from '~/shared/api/internal/utils';
import { AuthUserModel } from '~/shared/models/auth-user/AuthUserModel';

export const authUserQuery = makeQuery({
  queryKey: ['authUser'],
  queryFn: async () => {
    const authUser = await getAuthUser();
    if (!authUser) {
      return null;
    }
    return new AuthUserModel(authUser);
  },
});
