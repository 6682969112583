export function assertIsDefined<T>(value: T): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    throw new Error(`${value} is not defined`);
  }
}

export function assertIsString(value: unknown): asserts value is string {
  if (typeof value !== 'string') {
    throw new Error('Not a string');
  }
}

export function assertIsNonBlankString(
  value: unknown,
): asserts value is string {
  assertIsString(value);
  if (!value) {
    throw new Error('Expect string to be not blank');
  }
}

export function assertUnreachable(value: never): never {
  throw new Error(`Didn't expect to receive value "${value}"`);
}

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function asError(err: unknown): Error {
  if (err instanceof Error) {
    return err;
  }

  if (typeof err === 'string') {
    return new Error(err);
  }

  return new Error(JSON.stringify(err));
}

export function asInt(input: string | undefined) {
  assertIsDefined(input);
  const result = Number.parseInt(input, 10);

  if (Number.isNaN(result)) {
    throw new Error(`Unable to parse int from: "${input}"`);
  }

  return result;
}

export function asFloat(input: string | undefined) {
  assertIsDefined(input);

  const result = Number.parseFloat(input);

  if (Number.isNaN(result)) {
    throw new Error(`Unable to parse float from: "${input}"`);
  }

  return result;
}
