import {
  Envelope,
  Transport,
  TransportMakeRequestResponse,
} from '@sentry/types';

const MAX_BUFFER_SIZE = 30;
const DEFAULT_RESPONSE: TransportMakeRequestResponse = {};

export class ConsentTransport implements Transport {
  private shouldSend = false;

  private hasConsent = false;

  private requestBuffer: Envelope[] = [];

  constructor(
    private transport: Transport,
    private maxBufferSize = MAX_BUFFER_SIZE,
  ) {}

  flush(timeout?: number) {
    return this.transport.flush(timeout);
  }

  async send(request: Envelope): Promise<TransportMakeRequestResponse> {
    this.shouldSend = true;
    this.requestBuffer.push(request);

    if (this.requestBuffer.length > this.maxBufferSize) {
      this.requestBuffer.shift();
    }
    return this.trySend();
  }

  setConsent(hasConsent: boolean) {
    this.hasConsent = hasConsent;
    this.trySend();
  }

  private async trySend() {
    if (!this.hasConsent || !this.shouldSend) {
      return DEFAULT_RESPONSE;
    }

    const results = await Promise.all(
      this.requestBuffer.map((request) => this.transport.send(request)),
    );

    this.requestBuffer = [];

    return results.at(-1) || DEFAULT_RESPONSE;
  }
}
