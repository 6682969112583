import { ListMetricsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { KpiModel } from '~/pages/Dashboard/models/KpiModel';
import { KpiListModel } from './KpiListModel';

export class WaterStoredKpiModel extends KpiListModel {
  readonly waterStored: KpiModel;

  constructor(metricsResponse: ListMetricsResponse) {
    super(metricsResponse);

    this.waterStored = this.createKpiModel('WATER_STORED');
  }
}
