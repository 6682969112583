import { makeQuery } from '~/shared/api/internal/utils';
import { assertIsNonBlankString } from '~/types/assert-type';
import { listEvents } from '~/services/api/fetchers/events.api';
import { EventsModel } from '../models/EventsModel';

export const LIST_EVENTS_QUERY_KEY = 'listEvents';

export const listEventsQuery = ({ resourceName }: { resourceName: string }) => {
  assertIsNonBlankString(resourceName);
  return makeQuery({
    queryKey: [
      LIST_EVENTS_QUERY_KEY,
      {
        resourceName,
      },
    ],
    queryFn: async () => {
      const eventsResponse = await listEvents({ resourceName });
      return new EventsModel(eventsResponse);
    },
    staleTime: 60 * 5_000,
  });
};
