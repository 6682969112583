import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router-dom';
import { listSystemsQuery } from '~/shared/api/system.queries';
import { checkAuthorization } from '~/shared/auth/auth-utils';
import { assertIsDefined } from '~/types/assert-type';

export const systemEditPageLoader =
  (queryClient: QueryClient) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    await checkAuthorization(queryClient, request);

    const { systemId } = params;

    assertIsDefined(systemId);

    const systems = await queryClient.ensureQueryData(listSystemsQuery);

    return {
      systems,
    };
  };
