import { useEffect, useRef } from 'react';

// run once with a ref to avoid double loading due to react strict mode
export function useRunOnce(callback: () => void) {
  const hasRunRef = useRef(false);
  useEffect(() => {
    if (!hasRunRef.current) {
      callback();
      hasRunRef.current = true;
    }
  }, [callback]);
}
