import { Timestamp } from '@bufbuild/protobuf';
import { Metric } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import { DynamicReservoirInfo } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/reservoir_dynamic_info_pb';
import {
  ReservoirDynamicInfoModel,
  ReservoirDynamicInfoType,
} from '~/pages/ReservoirDynamicInfo/models/ReservoirDynamicInfoModel';
import { assertIsDefined } from '~/types/assert-type';

const createReservoirDynamicInfoModel = (
  infoType: ReservoirDynamicInfoType,
  color: string,
  metric?: Metric,
) => {
  assertIsDefined(metric);

  return new ReservoirDynamicInfoModel(metric, infoType, color);
};

export class ReservoirDynamicInfoListModel {
  readonly reservoirName: string;

  readonly predictedTime?: Timestamp;

  readonly predictedWaterHeight: ReservoirDynamicInfoModel;

  readonly ruleWaterHeight: ReservoirDynamicInfoModel;

  readonly forecastTemperature: ReservoirDynamicInfoModel;

  readonly forecastPrecipitation: ReservoirDynamicInfoModel;

  readonly receivingOtherReservoirs: ReservoirDynamicInfoModel;

  readonly receivingTap: ReservoirDynamicInfoModel;

  readonly dumpingOtherReservoirs: ReservoirDynamicInfoModel;

  readonly dumpingSewer: ReservoirDynamicInfoModel;

  constructor(response: DynamicReservoirInfo) {
    this.reservoirName = response.name;

    this.predictedTime = response.predictedTime;

    this.predictedWaterHeight = createReservoirDynamicInfoModel(
      'predictedWaterHeight',
      'blue',
      response.predictedWaterHeight,
    );

    this.ruleWaterHeight = createReservoirDynamicInfoModel(
      'ruleWaterHeight',
      'black',
      response.ruleWaterHeight,
    );

    this.forecastTemperature = createReservoirDynamicInfoModel(
      'forecastTemperature',
      'orange',
      response.forecastTemperature,
    );

    this.forecastPrecipitation = createReservoirDynamicInfoModel(
      'forecastPrecipitation',
      'lightblue',
      response.forecastPrecipitation,
    );

    this.receivingOtherReservoirs = createReservoirDynamicInfoModel(
      'receivingOtherReservoirs',
      'lightblue',
      response.receivingOtherReservoirs,
    );

    this.receivingTap = createReservoirDynamicInfoModel(
      'receivingTap',
      'blue',
      response.receivingTap,
    );

    this.dumpingOtherReservoirs = createReservoirDynamicInfoModel(
      'dumpingOtherReservoirs',
      'orange',
      response.dumpingOtherReservoirs,
    );

    this.dumpingSewer = createReservoirDynamicInfoModel(
      'dumpingSewer',
      'red',
      response.dumpingSewer,
    );
  }
}
