import {
  ListMetricsRequest_AggregationType,
  ListMetricsRequest_Target,
  ListMetricsResponse,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import {
  getWaterLevelMetrics,
  listMetrics,
} from '~/services/api/fetchers/metrics.api';
import { makeQuery } from '~/shared/api/internal/utils';
import { MetricsModel } from '~/shared/models/metrics/MetricsModel';
import { WaterLevelsModel } from '~/shared/models/metrics/waterLevelsModel/WaterLevelsModel';

export const LIST_METRIC_QUERY_KEY = 'listMetrics';

export const listMetricsQuery = ({
  targets,
  aggregation,
}: {
  targets: ListMetricsRequest_Target[];
  aggregation?: ListMetricsRequest_AggregationType;
}) =>
  makeQuery({
    queryKey: [
      LIST_METRIC_QUERY_KEY,
      {
        types: targets,
        aggregation,
      },
    ],
    queryFn: async () => {
      if (targets.length === 0) {
        return new MetricsModel();
      }
      const metricsResponse = await listMetrics(targets, aggregation);
      return new MetricsModel(metricsResponse);
    },
    staleTime: 60 * 5_000,
  });

export const waterLevelsMetricsQuery = (
  reservoirNames: string[],
  currentOnly?: boolean,
) =>
  makeQuery({
    queryKey: ['waterLevelMetrics', reservoirNames, currentOnly],
    queryFn: async () => {
      const waterLevelMetrics = reservoirNames.length
        ? await getWaterLevelMetrics(reservoirNames, currentOnly)
        : new ListMetricsResponse();
      return new WaterLevelsModel(waterLevelMetrics);
    },
  });
