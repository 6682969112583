import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router-dom';
import { requireUserRole } from '~/shared/auth/auth-utils';
import { RolesModel } from '~/shared/models/system/RolesModel';

export const systemCreatePageLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    await requireUserRole(queryClient, request, RolesModel.canCreateSystem);

    return null;
  };
