import { Timestamp } from '@bufbuild/protobuf';
import { DynamicReservoirInfo } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/reservoir_dynamic_info_pb';
import { aqoraClient } from '~/services/api/aqora-client';

export const getReservoirDynamicInfo = (
  reservoirName: string,
  timestamp?: Timestamp,
): Promise<DynamicReservoirInfo> =>
  aqoraClient.getDynamicReservoirInfo({
    name: reservoirName,
    beforeTime: timestamp,
  });
