import { useState } from 'react';
import { useRunOnce } from '~/pages/layout/hooks/useRunOnce';
import {
  ConsentedCookieGroups,
  getConsentedServices,
  onConsentChanged,
} from '~/services/cookiebar/onetrust';

export function useCookiebar(): ConsentedCookieGroups {
  const [consentedServices, setConsentedServices] =
    useState<ConsentedCookieGroups>(getConsentedServices);

  useRunOnce(() => {
    onConsentChanged(() => {
      const newConsentedServices = getConsentedServices();
      setConsentedServices(newConsentedServices);
    });
  });
  return consentedServices;
}
