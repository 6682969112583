import { ListMetricsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import {
  Metric_DataPoint,
  MetricType,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import { KpiModel, KpiType } from '~/pages/Dashboard/models/KpiModel';
import { getSensorValue } from '~/shared/models/metrics/metrics-utils';
import { assertIsDefined } from '~/types/assert-type';

function roundToDecimals(number: number, decimals: number) {
  const factor = 10 ** decimals;
  return Math.round(number * factor) / factor;
}

function litersToCubicMeters(liters: number) {
  return liters / 1000;
}

function getRoundedUsage(metricDataPoint: Metric_DataPoint) {
  return roundToDecimals(
    litersToCubicMeters(getSensorValue(metricDataPoint)),
    0,
  );
}

const metricsMapping = {
  RAIN_TOTAL: MetricType.KPI_RAIN_TOTAL_L,
  PLANT_USAGE: MetricType.KPI_PLANT_USAGE_L,
  WATER_STORED: MetricType.KPI_WATER_STORED_L,
};

export class KpiListModel {
  constructor(private metricsResponse: ListMetricsResponse) {}

  private getDataPoint(metricType: MetricType) {
    const metric = this.metricsResponse.metrics.find(
      ({ type }) => type === metricType,
    );

    assertIsDefined(metric);

    return metric.dataPoints[0];
  }

  public createKpiModel(type: KpiType) {
    let value;
    let timestamp;
    const dataPoint = this.getDataPoint(metricsMapping[type]);

    if (dataPoint) {
      const roundedUsage = getRoundedUsage(dataPoint);
      value = roundedUsage > 0 ? roundedUsage : 0;
      timestamp = dataPoint.timestamp;
    }

    return new KpiModel(type, value, timestamp?.toDate());
  }
}
