import { FirebaseError, initializeApp } from 'firebase/app';
import {
  confirmPasswordReset,
  getAuth,
  OAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { AuthError, AuthService } from '~/services/auth/AuthService';
import {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MICROSOFT_TENANT,
} from '~/services/environment/environment';

export class FirebaseAuthService implements AuthService {
  private firebaseApp = initializeApp({
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
  });

  private auth = getAuth(this.firebaseApp);

  isSignedIn: boolean;

  constructor() {
    this.isSignedIn = !!this.auth.currentUser;
    this.onAuthStateChanged((isSignedIn) => {
      this.isSignedIn = isSignedIn;
    });
  }

  initialized = this.auth.authStateReady();

  async getIdentityToken(): Promise<string | null> {
    await this.initialized;
    return this.auth.currentUser?.getIdToken() ?? null;
  }

  async signIn(email: string, password: string): Promise<AuthError | null> {
    try {
      await signInWithEmailAndPassword(this.auth, email, password);
    } catch (error) {
      if (error instanceof FirebaseError) {
        return new AuthError(error.code, error.message);
      }
      throw error;
    }
    return null;
  }

  async signInWithMicrosoft(email: string): Promise<AuthError | null> {
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      tenant: FIREBASE_MICROSOFT_TENANT,
      // Pre-fill the email address field.
      login_hint: email,
    });
    try {
      await signInWithPopup(this.auth, provider);
    } catch (error) {
      // REMOVE
      // eslint-disable-next-line no-console
      console.error('SSO ERROR:', error);
      if (error instanceof FirebaseError) {
        return new AuthError(error.code, error.message);
      }
      throw error;
    }
    return null;
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    await sendPasswordResetEmail(this.auth, email);
  }

  async confirmPasswordReset(
    oobCode: string,
    newPassword: string,
  ): Promise<void> {
    await confirmPasswordReset(this.auth, oobCode, newPassword);
  }

  signOut(): Promise<void> {
    return signOut(this.auth);
  }

  onAuthStateChanged(callback: (isSignedIn: boolean) => void): () => void {
    return onAuthStateChanged(this.auth, async (user) => {
      callback(!!user);
    });
  }
}
