import {
  SystemPermission,
  User,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { RoleBySystem } from '~/pages/Users/types';
import { RolesModel, UserRole } from '~/shared/models/system/RolesModel';

export class UserModel {
  readonly name: string;

  readonly id: string;

  readonly email: string;

  readonly permissions: SystemPermission[];

  readonly systemsByRole: Map<UserRole, string[]>;

  readonly roleBySystem: Map<string, UserRole>;

  constructor(user: User) {
    this.name = user.name;
    this.id = user.name.replace('users/', '');
    this.email = user.email;
    this.permissions = user.systemPermissions;
    this.systemsByRole = this.permissionsToSystemsByRole();
    this.roleBySystem = this.permissionsToRoleBySystem();
  }

  private permissionsToRoleBySystem() {
    const roleBySystem = new Map<string, UserRole>();

    this.permissions.forEach(({ systemName, permissions }) => {
      const role = RolesModel.permissionsToRole(permissions);
      roleBySystem.set(systemName, role);
    });

    return roleBySystem;
  }

  private permissionsToSystemsByRole() {
    const systemsByRole = new Map<UserRole, string[]>();

    this.permissions.forEach(({ systemName, permissions }) => {
      const role = RolesModel.permissionsToRole(permissions);

      const systems = systemsByRole.get(role) || [];
      systems.push(systemName);
      systemsByRole.set(role, systems);
    });

    return systemsByRole;
  }

  static newRolesToPermissions(newRoles: RoleBySystem) {
    const newPermissions: SystemPermission[] = [];

    newRoles.forEach((role, systemName) => {
      newPermissions.push(
        new SystemPermission({
          systemName,
          permissions: RolesModel.roleToPermissions(role),
        }),
      );
    });

    return newPermissions;
  }

  getEmptyPermissionsForAllUserSystems() {
    return (
      this.permissions?.map(
        ({ systemName }) =>
          new SystemPermission({
            systemName,
            permissions: [],
          }),
      ) || []
    );
  }
}
