import { assertIsNonBlankString } from '~/types/assert-type';

export const idFromName = (name: string) => {
  assertIsNonBlankString(name);
  return window.btoa(name);
};

export const nameFromId = (id: string) => {
  assertIsNonBlankString(id);
  return window.atob(id);
};
