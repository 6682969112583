import {
  Profile,
  SystemPermission_Permission,
  User,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { idFromName } from '~/shared/models/id-utils';
import { RolesModel, UserRole } from '~/shared/models/system/RolesModel';
import { ProfileModel } from '~/shared/models/auth-user/ProfileModel';
import { assertIsDefined } from '~/types/assert-type';

export class AuthUserModel {
  readonly email: string;

  readonly permissions: Set<SystemPermission_Permission>;

  readonly profile: ProfileModel;

  readonly roles: Set<UserRole>;

  private roleBySystemId: Map<string, UserRole>;

  private permissionsBySystemId: Map<string, Set<SystemPermission_Permission>>;

  readonly systemsWithAdminRole: Set<string>;

  constructor(user: User) {
    this.email = user.email;
    assertIsDefined(user.profile);
    const displayName = user.profile.displayName || user.email.split('@')[0];

    this.permissions = new Set(
      user.systemPermissions.flatMap(
        (systemPermission) => systemPermission.permissions,
      ),
    );

    this.roles = new Set(
      user.systemPermissions.flatMap((systemPermission) =>
        RolesModel.permissionsToRole(systemPermission.permissions),
      ),
    );

    this.roleBySystemId = new Map(
      user.systemPermissions.map((systemPermission) => [
        idFromName(systemPermission.systemName),
        RolesModel.permissionsToRole(systemPermission.permissions),
      ]),
    );

    this.profile = new ProfileModel(
      new Profile({ ...user.profile, displayName }),
    );

    if (this.permissions.has(SystemPermission_Permission.UNSPECIFIED)) {
      throw new Error('User has unspecified permission');
    }

    this.permissionsBySystemId = new Map(
      user.systemPermissions.map((systemPermission) => [
        idFromName(systemPermission.systemName),
        new Set(systemPermission.permissions),
      ]),
    );

    this.systemsWithAdminRole = new Set(
      user.systemPermissions
        .filter((systemPermission) => {
          const role = RolesModel.permissionsToRole(
            systemPermission.permissions,
          );
          return role === 'admin' || role === 'internal_admin';
        })
        .map((systemPermission) => systemPermission.systemName),
    );
  }

  shouldShowSystemsList() {
    return this.permissionsBySystemId.size > 1;
  }

  hasRole(role: UserRole) {
    return this.roles.has(role);
  }

  hasRoleForSystemId(role: UserRole, systemId: string) {
    return this.roleBySystemId.get(systemId) === role;
  }

  hasPermission(permission: SystemPermission_Permission) {
    return this.permissions.has(permission);
  }

  hasPermissionForSystemId(
    permission: SystemPermission_Permission,
    systemId: string,
  ) {
    const permissions = this.permissionsBySystemId.get(systemId);

    if (!permissions) {
      return false;
    }

    return permissions.has(permission);
  }
}
