import { createReleaseVersion } from './create-release-version';

/* eslint-disable no-restricted-syntax */
function queryEnv(name: string): string | undefined {
  return import.meta.env[name] || undefined;
}

function getEnv(name: string): string {
  const result = queryEnv(name);
  if (!result) {
    throw new Error(`Env var ${name} was not defined`);
  }
  return result;
}

export function createReleaseVersionFromEnv(): string {
  const buildTime = queryEnv('VITE_BUILD_TIME');
  const tagName = queryEnv('VITE_TAG_NAME');
  const shortSha = queryEnv('VITE_SHORT_SHA');
  const environment = queryEnv('VITE_ENVIRONMENT');

  return createReleaseVersion({
    buildTime,
    tagName,
    environment,
    shortSha,
  });
}

export const SKIP_SIGNIN = queryEnv('VITE_SKIP_SIGNIN') === 'true';
export const TRANSPORT_URL = getEnv('VITE_TRANSPORT_URL');
export const ENABLE_MOCKS = queryEnv('VITE_ENABLE_MOCKS') === 'true';
export const DEVELOPMENT_MODE = queryEnv('MODE') === 'development';

export const TEST_MODE = queryEnv('MODE') === 'test';

export const ENVIRONMENT = getEnv('VITE_ENVIRONMENT');

export const PRODUCTION_ENVIRONMENT = ENVIRONMENT === 'prod';

export const PRODUCTION_MODE = import.meta.env.PROD;

export const BUILD_ID = queryEnv('VITE_BUILD_ID');

export const RELEASE_VERSION = createReleaseVersionFromEnv();

export const FIREBASE_API_KEY = getEnv('VITE_FIREBASE_API_KEY');
export const FIREBASE_AUTH_DOMAIN = getEnv('VITE_FIREBASE_AUTH_DOMAIN');
export const FIREBASE_MICROSOFT_TENANT = getEnv(
  'VITE_FIREBASE_MICROSOFT_TENANT',
);

export const GOOGLE_ANALYTICS_ID = queryEnv('VITE_GOOGLE_ANALYTICS_ID');
export const SENTRY_DSN = queryEnv('VITE_SENTRY_DSN');

export const ONETRUST_SCRIPT_ID = getEnv('VITE_ONETRUST_SCRIPT_ID');

export const GOOGLE_PLACES_API_KEY = getEnv('VITE_GOOGLE_PLACES_API_KEY');
