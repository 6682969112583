import {
  createConnectTransport,
  createGrpcWebTransport,
} from '@connectrpc/connect-web';
import { Interceptor } from '@connectrpc/connect';
import { authInterceptor } from '~/services/auth/auth-interceptor';
import {
  ENABLE_MOCKS,
  TRANSPORT_URL,
} from '~/services/environment/environment';

const baseUrl = TRANSPORT_URL;

const interceptors: Interceptor[] = [authInterceptor];

export const transport = ENABLE_MOCKS
  ? createConnectTransport({
      baseUrl,
      interceptors,
    })
  : createGrpcWebTransport({
      baseUrl,
      interceptors,
    });
