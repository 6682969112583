import { ListEventsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { aqoraClient } from '~/services/api/aqora-client';

const EVENTS_PAGE_SIZE = BigInt(200);

export const listEvents = async ({
  resourceName,
}: {
  resourceName: string;
  pageSize?: number;
}): Promise<ListEventsResponse> =>
  await aqoraClient.listEvents({
    pageSize: EVENTS_PAGE_SIZE,
    resourceName,
  });
