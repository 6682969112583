export const validatePassword = (
  password: string,
  passwordConfirm: string,
  oobCode: string,
) => {
  if (typeof password !== 'string') {
    throw new Error('Expected password to be a text.');
  }

  if (typeof passwordConfirm !== 'string') {
    throw new Error('Expected passwordConfirm to be a text.');
  }

  if (password.length < 6) {
    throw new Error('Password must be at least 6 characters long.');
  }

  if (password !== passwordConfirm) {
    throw new Error(
      `Passwords don't match. Please make sure your confirm password is identical.`,
    );
  }

  if (!oobCode || oobCode.length === 0) {
    throw new Error(
      'Verification code does not exist. Please refresh the page.',
    );
  }
};
