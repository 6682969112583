import { ListEventsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { EventModel } from './EventModel';

export class EventsModel {
  readonly events: EventModel[] | undefined;

  constructor(eventsResponse: ListEventsResponse) {
    if (eventsResponse.events.length > 0) {
      this.events = eventsResponse.events.map(
        (event, index) => new EventModel(event, index),
      );
    } else {
      this.events = undefined;
    }
  }
}
