import { SystemPermission_Permission } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { TFunction } from 'i18next';
import { assertUnreachable } from '~/types/assert-type';

export type UserRole =
  | 'designer'
  | 'installer'
  | 'customer'
  | 'viewer'
  | 'admin'
  | 'internal_engineer'
  | 'internal_admin'
  | 'no_access';

const basicRoles: UserRole[] = [
  'designer',
  'installer',
  'customer',
  'viewer',
  'admin',
];

const internalRoles: UserRole[] = ['internal_engineer', 'internal_admin'];

const allRoles = [...basicRoles, ...internalRoles];

export class RolesModel {
  static canCreateSystem: UserRole[] = ['admin', 'internal_admin'];

  static canSeeIssuesList: UserRole[] = [
    'installer',
    'admin',
    'internal_engineer',
    'internal_admin',
  ];

  static canSeeUsersList: UserRole[] = ['admin', 'internal_admin'];

  static permissions: Record<UserRole, SystemPermission_Permission[]> = {
    no_access: [],
    designer: [
      SystemPermission_Permission.VIEWER,
      SystemPermission_Permission.DESIGN,
    ],
    installer: [
      SystemPermission_Permission.VIEWER,
      SystemPermission_Permission.CONTROL,
      SystemPermission_Permission.DESIGN,
      SystemPermission_Permission.COMMISSIONING,
    ],
    customer: [
      SystemPermission_Permission.VIEWER,
      SystemPermission_Permission.CONTROL,
    ],
    viewer: [SystemPermission_Permission.VIEWER],
    admin: [
      SystemPermission_Permission.VIEWER,
      SystemPermission_Permission.CONTROL,
      SystemPermission_Permission.DESIGN,
      SystemPermission_Permission.COMMISSIONING,
      SystemPermission_Permission.USER_MANAGEMENT,
    ],
    internal_engineer: [
      SystemPermission_Permission.VIEWER,
      SystemPermission_Permission.CONTROL,
      SystemPermission_Permission.DESIGN,
      SystemPermission_Permission.COMMISSIONING,
      SystemPermission_Permission.ADMIN,
    ],
    internal_admin: [
      SystemPermission_Permission.VIEWER,
      SystemPermission_Permission.CONTROL,
      SystemPermission_Permission.DESIGN,
      SystemPermission_Permission.COMMISSIONING,
      SystemPermission_Permission.ADMIN,
      SystemPermission_Permission.USER_MANAGEMENT,
    ],
  };

  static permissionsToRole(
    permissions: SystemPermission_Permission[],
  ): UserRole {
    const sortedRoles = Object.entries(RolesModel.permissions).sort(
      ([, aPermissions], [, bPermissions]) =>
        bPermissions.length - aPermissions.length,
    );

    for (const [role, rolePermissions] of sortedRoles) {
      if (this.assertPermissions(permissions, rolePermissions)) {
        return role as UserRole;
      }
    }
    throw new Error('Unspecified role');
  }

  private static assertPermissions(
    userPermissions: SystemPermission_Permission[],
    rolePermissions: SystemPermission_Permission[],
  ) {
    if (rolePermissions.length === 0) {
      return userPermissions.length === 0;
    }

    return rolePermissions.every((permission) =>
      userPermissions.includes(permission),
    );
  }

  static roleToPermissions(role: UserRole): SystemPermission_Permission[] {
    const permissions = RolesModel.permissions[role];
    if (!permissions) {
      throw new Error(`Role ${role} is not defined`);
    }
    return permissions;
  }

  static getRoleLabel(role: UserRole, t: TFunction) {
    switch (role) {
      case 'designer':
        return t('userManagement.roles.designer');
      case 'installer':
        return t('userManagement.roles.installer');
      case 'customer':
        return t('userManagement.roles.customer');
      case 'viewer':
        return t('userManagement.roles.viewer');
      case 'admin':
        return t('userManagement.roles.admin');
      case 'internal_engineer':
        return t('userManagement.roles.internalEngineer');
      case 'internal_admin':
        return t('userManagement.roles.internalAdmin');
      case 'no_access':
        return t('userManagement.roles.noAccess');
      default:
        return assertUnreachable(role);
    }
  }

  static getRolesList(getFullList: boolean): UserRole[] {
    if (getFullList) {
      return allRoles;
    }
    return basicRoles;
  }
}
