import { AppBar, Box, IconButton, Skeleton, Toolbar } from '@mui/material';
import { useState } from 'react';
import { useNavigation } from 'react-router-dom';

import {
  Drawer,
  RenderDrawerContentProps,
} from '~/pages/layout/components/Navigation/Drawer';

import Logo from '~/pages/layout/components/Navigation/logo.png';
import { DRAWER_WIDTH } from '~/pages/layout/layout-constants';
import { MenuIcon } from '~/shared/components/Icons/Icons';

export function DrawerContainer({
  renderDrawerContent,
}: RenderDrawerContentProps) {
  const navigation = useNavigation();
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          width: { md: `calc(100% - ${DRAWER_WIDTH}px)` },
          ml: { md: `${DRAWER_WIDTH}px` },
          display: { md: 'none' },
        }}
      >
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open navigation"
            edge="start"
            onClick={() => setMobileOpen(true)}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          {navigation.state === 'loading' ? (
            <Skeleton width={200} />
          ) : (
            <Box
              component="img"
              src={Logo}
              alt="Wavin logo"
              sx={{ width: '80px', height: 'auto' }}
            />
          )}
        </Toolbar>
      </AppBar>

      <Box component="nav" width={{ md: DRAWER_WIDTH }} flexShrink={{ sm: 0 }}>
        <Drawer
          isOpen={mobileOpen}
          onClose={() => setMobileOpen(false)}
          renderDrawerContent={renderDrawerContent}
        />
      </Box>
    </>
  );
}
