import { Code } from '@connectrpc/connect';
import { DeviceDetailsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { isApiError } from '~/services/api/api-utils';
import { aqoraClient } from '~/services/api/aqora-client';

export const getDeviceDetails = async (
  deviceName: string,
): Promise<DeviceDetailsResponse | null> => {
  try {
    return await aqoraClient.getDeviceDetails({
      deviceName,
    });
  } catch (err) {
    if (isApiError(err) && err.code === Code.NotFound) {
      return null;
    }

    if (
      isApiError(err) &&
      err.message === '[internal] particle response code was not OK, but 404'
    ) {
      return null;
    }

    throw err;
  }
};
