import { QueryClient } from '@tanstack/react-query';
import {
  ListSystemsIssuesResponse,
  ListSystemsResponse,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { LoaderFunctionArgs } from 'react-router-dom';
import { listSystemsQuery } from '~/shared/api/system.queries';
import { listSystemsIssuesQuery } from './api/listSystemsIssues.queries';
import { checkAuthorization } from '~/shared/auth/auth-utils';

export type SystemsListLoaderResult = {
  systems: ListSystemsResponse;
  systemsIssues: ListSystemsIssuesResponse;
};

export const systemsListLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs): Promise<SystemsListLoaderResult> => {
    await checkAuthorization(queryClient, request);

    const listSystemsResponsePromise =
      queryClient.ensureQueryData(listSystemsQuery);

    const listSystemsIssuesResponsePromise = queryClient.ensureQueryData(
      listSystemsIssuesQuery,
    );

    const [listSystemsResponse, listSystemsIssuesResponse] = await Promise.all([
      listSystemsResponsePromise,
      listSystemsIssuesResponsePromise,
    ]);

    return {
      systems: listSystemsResponse,
      systemsIssues: listSystemsIssuesResponse,
    };
  };
