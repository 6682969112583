import { Grid, Box } from '@mui/material';

const sizes = {
  small: 10,
  medium: 24,
};

type DotProps = {
  color?: string;
  size?: keyof typeof sizes;
  variant?: 'transparent' | 'filled';
};

export function Dot({
  color = 'secondary.light',
  size = 'small',
  variant = 'filled',
}: DotProps): JSX.Element {
  const hasBorder = variant === 'transparent';
  const hasBackgroundColor = variant === 'filled';

  return (
    <Grid
      sx={{ width: 'auto' }}
      container
      alignItems="center"
      justifyContent="center"
      wrap="nowrap"
    >
      <Box
        sx={[
          hasBorder && {
            borderWidth: (theme) => theme.border.size,
            borderColor: color,
            borderStyle: 'solid',
          },
          {
            backgroundColor: hasBackgroundColor ? color : 'transparent',
            borderRadius: '50%',
            width: sizes[size],
            height: sizes[size],
          },
        ]}
      />
    </Grid>
  );
}
