import { RainwaterKpisModel } from '~/pages/Dashboard/models/RainwaterKpisModel';
import { WaterStoredKpiModel } from '~/pages/Dashboard/models/WaterStoredKpiModel';
import {
  getWaterStoredKpi,
  listRainwaterKpis,
} from '~/services/api/fetchers/kpi.api';
import { makeQuery } from '~/shared/api/internal/utils';
import {
  DateRange,
  getDefaultDateRange,
} from '~/shared/models/metrics/date-range';

export const listRainwaterKpisQuery = (
  systemName: string,
  dateRange: DateRange = getDefaultDateRange(),
) =>
  makeQuery({
    queryKey: ['listRainwaterKpis', { systemName }, dateRange],
    queryFn: async () => {
      const listMetricsResponse = await listRainwaterKpis(
        systemName,
        dateRange,
      );
      return new RainwaterKpisModel(listMetricsResponse);
    },
  });

export const getWaterStoredKpiQuery = (systemName: string) =>
  makeQuery({
    queryKey: ['getWaterStoredKpi', { systemName }],
    queryFn: async () => {
      const listMetricsResponse = await getWaterStoredKpi(systemName);
      return new WaterStoredKpiModel(listMetricsResponse);
    },
  });
