import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { GOOGLE_ANALYTICS_ID } from '~/services/environment/environment';

export const useGoogleAnalytics = ({
  googleAnalyticsId = GOOGLE_ANALYTICS_ID,
  enabled,
}: {
  googleAnalyticsId?: string;
  enabled?: boolean;
}) => {
  const initalizedRef = useRef(false);
  useEffect(() => {
    if (!googleAnalyticsId) {
      return;
    }
    if (!enabled) {
      return;
    }
    if (initalizedRef.current) {
      return;
    }
    ReactGA.initialize(googleAnalyticsId);
    initalizedRef.current = true;
  }, [enabled, googleAnalyticsId]);
};
