import {
  ListIssuesResponse,
  ListSystemsResponse,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { logError } from '~/shared/utils/log-error';
import { SystemModel } from '~/shared/models/system/SystemModel';

export class ListSystemsModel {
  readonly systems: SystemModel[];

  private systemByIdMap = new Map<string, SystemModel>();

  constructor(
    private listSystemsResponse: ListSystemsResponse,
    issuesResponse: ListIssuesResponse = new ListIssuesResponse(),
  ) {
    this.systems = listSystemsResponse.systems
      .flatMap((system) => {
        try {
          return new SystemModel(system, issuesResponse);
        } catch (error) {
          logError(
            'Encountered a system with a data issue',
            {
              resourceName: system.name,
              title: system.title,
            },
            error,
          );
          return [];
        }
      })
      .sort((a, b) => a.title.localeCompare(b.title));

    this.systems.forEach((system) => {
      this.systemByIdMap.set(system.id, system);
    });
  }

  systemById(systemId: string) {
    return this.systemByIdMap.get(systemId);
  }
}
