import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router-dom';
import { ListSystemsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { requireUserRole } from '~/shared/auth/auth-utils';
import { RolesModel } from '~/shared/models/system/RolesModel';
import { listUsersQuery } from '~/pages/Users/api/users.queries';
import { UsersModel } from '~/pages/Users/models/UsersModel';
import { listSystemsQuery } from '~/shared/api/system.queries';

export type UsersLoaderData = {
  users: UsersModel;
  systems: ListSystemsResponse;
};

export const usersLoader =
  (queryClient: QueryClient) =>
  async ({
    request,
  }: LoaderFunctionArgs): Promise<UsersLoaderData | Response> => {
    await requireUserRole(queryClient, request, RolesModel.canSeeUsersList);
    const listSystemsResponsePromise =
      queryClient.ensureQueryData(listSystemsQuery);

    const usersPromise = queryClient.ensureQueryData(listUsersQuery());

    const [users, systems] = await Promise.all([
      usersPromise,
      listSystemsResponsePromise,
    ]);
    return { users, systems };
  };
