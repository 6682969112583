import { makeQuery } from '~/shared/api/internal/utils';
import { listUsers } from '~/pages/Users/api/users.api';
import { UsersModel } from '~/pages/Users/models/UsersModel';

export const listUsersQuery = () =>
  makeQuery({
    queryKey: ['users'],
    queryFn: async () => {
      const response = await listUsers();

      return new UsersModel(response);
    },
  });
