import { QueryClient } from '@tanstack/react-query';
import {
  DeviceDetailsResponse,
  ListMetricsRequest_AggregationType,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { SystemPermission_Permission } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { listEventsQuery } from '~/pages/Advanced/api/events.queries';
import { listMetricsQuery } from '~/shared/api/metrics.queries';
import { listSystemsQuery } from '~/shared/api/system.queries';
import {
  checkIfUserHasPermissionForSystem,
  requireUserPermissionForSystemId,
} from '~/shared/auth/auth-utils';
import { ListSystemsModel } from '~/shared/models/system/ListSystemsModel';
import { assertIsDefined } from '~/types/assert-type';
import { getDeviceDetailsQuery } from './api/deviceDetails.queries';
import { MetricsModel } from '~/shared/models/metrics/MetricsModel';
import { EventsModel } from '~/pages/Advanced/models/EventsModel';

export type AdvancedPageLoaderData = {
  metrics?: MetricsModel;
  deviceDetails?: DeviceDetailsResponse;
  events?: EventsModel;
};

export const advancedLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
    request,
  }: LoaderFunctionArgs): Promise<AdvancedPageLoaderData | Response> => {
    const { productId, systemId } = params;

    assertIsDefined(productId);
    assertIsDefined(systemId);

    await requireUserPermissionForSystemId(
      queryClient,
      request,
      [
        SystemPermission_Permission.ADMIN,
        SystemPermission_Permission.COMMISSIONING,
      ],
      systemId,
    );

    const listSystemsResponse =
      await queryClient.ensureQueryData(listSystemsQuery);

    const listSystemsModel = new ListSystemsModel(listSystemsResponse);

    const product = listSystemsModel
      .systemById(systemId)
      ?.productById(productId);

    if (!product) {
      return redirect('/not-found');
    }

    const isAdmin = await checkIfUserHasPermissionForSystem(
      queryClient,
      request,
      systemId,
      SystemPermission_Permission.ADMIN,
    );

    const [metricsModel, deviceDetails, events] = await Promise.all([
      queryClient.ensureQueryData(
        listMetricsQuery({
          targets: product.availableMetricTargets,
          aggregation: ListMetricsRequest_AggregationType.MOST_RECENT_ONLY,
        }),
      ),
      isAdmin
        ? queryClient.ensureQueryData(
            getDeviceDetailsQuery(product.device.name),
          )
        : null,
      isAdmin
        ? queryClient.ensureQueryData(
            listEventsQuery({
              resourceName: product.device.name,
            }),
          )
        : null,
    ]);

    return {
      metrics: metricsModel ?? undefined,
      deviceDetails: deviceDetails ?? undefined,
      events: events ?? undefined,
    };
  };
