import { Interceptor } from '@connectrpc/connect';
import { authService } from '~/services/auth/auth-service';
import { ENABLE_MOCKS } from '~/services/environment/environment';
import { assertIsDefined } from '~/types/assert-type';

export const authInterceptor: Interceptor = (next) => async (req) => {
  if (!ENABLE_MOCKS) {
    const token = await authService.getIdentityToken();
    assertIsDefined(token);
    req.header.set('Authorization', `Bearer ${token}`);
  }
  return next(req);
};
