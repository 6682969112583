import { ListMetricsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { KpiModel } from '~/pages/Dashboard/models/KpiModel';
import { KpiListModel } from './KpiListModel';

export class RainwaterKpisModel extends KpiListModel {
  readonly rainTotal: KpiModel;

  readonly plantUsage: KpiModel;

  constructor(metricsResponse: ListMetricsResponse) {
    super(metricsResponse);

    this.plantUsage = this.createKpiModel('PLANT_USAGE');

    this.rainTotal = this.createKpiModel('RAIN_TOTAL');
  }
}
