import { Drawer as MUIDrawer, Grid, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactNode } from 'react';
import { Logo } from '~/pages/layout/components/Navigation/Logo';
import { DRAWER_WIDTH } from '~/pages/layout/layout-constants';
import { CloseIcon } from '~/shared/components/Icons/Icons';
import { useIsSignedIn } from '~/shared/hooks/useIsSignedIn';
import { useAppTheme } from '~/shared/theme/theme';

export type RenderDrawerContentProps = {
  renderDrawerContent?: (onClose: () => void) => ReactNode;
};

type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
} & RenderDrawerContentProps;

export function Drawer({ isOpen, onClose, renderDrawerContent }: DrawerProps) {
  const isSignedIn = useIsSignedIn();
  const theme = useAppTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <MUIDrawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={isMobile ? isOpen : true}
      onClose={onClose}
      ModalProps={{
        keepMounted: isMobile, // Better open performance on mobile.
      }}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: DRAWER_WIDTH,
          borderRight: isSignedIn ? theme.palette.supportive.dark : 0,
        },
      }}
    >
      <Grid
        container
        direction="column"
        minHeight="100%"
        height="100%"
        flexWrap="nowrap"
        justifyContent="space-between"
        py={5}
      >
        <Grid item>
          {isMobile && (
            <IconButton
              onClick={onClose}
              sx={{ position: 'absolute', top: 3, left: 3 }}
            >
              <CloseIcon color="primary" />
            </IconButton>
          )}
          <Logo />
        </Grid>

        {renderDrawerContent?.(onClose)}
      </Grid>
    </MUIDrawer>
  );
}
