/* eslint-disable no-restricted-syntax */
import {
  ONETRUST_SCRIPT_ID,
  PRODUCTION_ENVIRONMENT,
} from '~/services/environment/environment';

declare global {
  interface Window {
    OptanonWrapper: () => void;
    OnetrustActiveGroups?: string;
  }

  interface DomainData {}

  const OneTrust: {
    testLog(): void;
    GetDomainData: () => DomainData;
    ToggleInfoDisplay: () => void;
  };
}

export const PERFORMANCE_GROUP_ID = 'C0002';
export const ANALYTICS_GROUP_ID = 'C0007';

export function loadCookiebarSdk() {
  const domainScriptPostFix = PRODUCTION_ENVIRONMENT ? '' : '-test';
  const script = document.createElement('script');
  script.src = 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js';
  script.type = 'text/javascript';
  script.setAttribute(
    'data-domain-script',
    `${ONETRUST_SCRIPT_ID}${domainScriptPostFix}`,
  );
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
}

export function getActiveCookieGroups() {
  return window.OnetrustActiveGroups?.split(',').filter(Boolean) ?? [];
}

export function onConsentChanged(callback: () => void) {
  window.OptanonWrapper = callback;
}

export function showCookiePreferences() {
  OneTrust.ToggleInfoDisplay();
}

export interface ConsentedCookieGroups {
  analytics: boolean;
  performance: boolean;
}

export function getConsentedServices() {
  const activeCookieGroups = getActiveCookieGroups();
  return {
    analytics: activeCookieGroups.includes(ANALYTICS_GROUP_ID),
    performance: activeCookieGroups.includes(PERFORMANCE_GROUP_ID),
  };
}
