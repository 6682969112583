import { useQuery } from '@tanstack/react-query';
import { SystemPermission_Permission } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { authUserQuery } from '~/shared/api/auth-user.queries';
import { hasAcceptedTerms } from '~/shared/auth/auth-utils';
import { useIsSignedIn } from '~/shared/hooks/useIsSignedIn';
import { UserRole } from '~/shared/models/system/RolesModel';
import { AuthUserModel } from '~/shared/models/auth-user/AuthUserModel';
import { assertIsDefined } from '~/types/assert-type';

export function useAuthUser(): AuthUserModel | undefined {
  const isSignedIn = useIsSignedIn();
  const { data: authUser } = useQuery({
    ...authUserQuery,
    enabled: isSignedIn,
  });
  return authUser ?? undefined;
}

export function useRequiredAuthUser() {
  const authUser = useAuthUser();
  assertIsDefined(authUser);
  return authUser;
}

export const useUserPermissions = (): Set<SystemPermission_Permission> => {
  const authUser = useAuthUser();
  return authUser?.permissions ?? new Set<SystemPermission_Permission>();
};

export const useHasPermission = (
  requestedUserPermission: SystemPermission_Permission,
): boolean => {
  const permissions = useUserPermissions();
  return permissions.has(requestedUserPermission);
};

export const useHasPermissionForSystem = (
  requestedUserPermissions:
    | SystemPermission_Permission
    | SystemPermission_Permission[],
  systemId: string,
): boolean => {
  const authUser = useAuthUser();

  const permissions = Array.isArray(requestedUserPermissions)
    ? requestedUserPermissions
    : [requestedUserPermissions];

  return permissions.some(
    (permission) =>
      authUser?.hasPermissionForSystemId(permission, systemId) ?? false,
  );
};

export const useHasRole = (
  requestedUserRoles: UserRole | UserRole[],
): boolean => {
  const authUser = useAuthUser();

  const roles = Array.isArray(requestedUserRoles)
    ? requestedUserRoles
    : [requestedUserRoles];

  return roles.some((role) => authUser?.hasRole(role) ?? false);
};

export const useHasRoleForSystem = (
  requestedUserRoles: UserRole | UserRole[],
  systemId: string,
): boolean => {
  const authUser = useAuthUser();

  const roles = Array.isArray(requestedUserRoles)
    ? requestedUserRoles
    : [requestedUserRoles];

  return roles.some(
    (role) => authUser?.hasRoleForSystemId(role, systemId) ?? false,
  );
};

export const useHasAcceptedTerms = () => {
  const authUser = useAuthUser();

  if (!authUser) {
    return false;
  }

  return hasAcceptedTerms(authUser.profile.lastAcceptedTermsAndConditions);
};
