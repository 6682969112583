import { TFunction } from 'i18next';

export interface AuthService {
  initialized: Promise<void>;
  readonly isSignedIn: boolean;

  getIdentityToken(): Promise<string | null>;

  signIn(email: string, password: string): Promise<AuthError | null>;

  signInWithMicrosoft(email: string): Promise<AuthError | null>;

  signOut(): Promise<void>;

  sendPasswordResetEmail(email: string): Promise<void>;

  confirmPasswordReset(oobCode: string, newPassword: string): Promise<void>;

  onAuthStateChanged(callback: (isSignedIn: boolean) => void): () => void;
}

export class AuthError extends Error {
  constructor(
    readonly code: string,
    message: string,
  ) {
    super(message);
  }

  getLabel(t: TFunction) {
    switch (this.code) {
      case 'auth/invalid-login-credentials': // Firebase does not expose this error code
        return t('auth.errors.auth/invalid-login-credentials');
      case 'auth/invalid-email':
        return t('auth.errors.auth/invalid-email');
      case 'auth/operation-not-allowed':
        return t('auth.errors.auth/operation-not-allowed');
      case 'auth/internal-error':
        return t('auth.errors.auth/internal-error');
      case 'auth/invalid-provider-id':
        return t('auth.errors.auth/invalid-provider-id');
      case 'auth/too-many-requests':
        return t('auth.errors.auth/too-many-requests');
      default:
        return t('auth.errors.default');
    }
  }
}
