import { ListMetricsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';

export class WaterLevelsModel {
  private waterLevelByReservoirName: Map<string, number | undefined>;

  constructor(private metricResponse: ListMetricsResponse) {
    this.waterLevelByReservoirName = new Map<string, number | undefined>();

    this.metricResponse.metrics.forEach((metric) => {
      const waterLevel = metric.dataPoints[0]?.value.value;

      this.waterLevelByReservoirName.set(
        metric.resourceName,
        waterLevel !== undefined ? Number(waterLevel) : undefined,
      );
    });
  }

  getWaterLevelByReservoirName(reservoirName: string): number | undefined {
    return this.waterLevelByReservoirName.get(reservoirName);
  }
}
