import { QueryClient } from '@tanstack/react-query';
import { LoaderFunction, LoaderFunctionArgs, redirect } from 'react-router-dom';
import { getAuthUser, hasAcceptedTerms } from '~/shared/auth/auth-utils';
import { createDashboardRoute } from '~/shared/models/create-routes';
import { assertIsDefined } from '~/types/assert-type';

export const termsPageLoader =
  (queryClient: QueryClient): LoaderFunction =>
  async ({ request }: LoaderFunctionArgs) => {
    const authUser = await getAuthUser(queryClient, request);

    assertIsDefined(authUser);

    if (hasAcceptedTerms(authUser.profile.lastAcceptedTermsAndConditions)) {
      return redirect(createDashboardRoute());
    }

    return null;
  };
