import { ListUsersResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { UserModel } from '~/pages/Users/models/UserModel';

export class UsersModel {
  readonly users: UserModel[];

  constructor(listUsersResponse: ListUsersResponse) {
    this.users = listUsersResponse.users.map((user) => new UserModel(user));
  }
}
