import { useQuery } from '@tanstack/react-query';
import { ListSystemsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import { listSystemsQuery } from '~/shared/api/system.queries';
import { useOpenIssuesQuery } from '~/shared/hooks/issues-hooks';
import { ListSystemsModel } from '~/shared/models/system/ListSystemsModel';
import { SystemModel } from '~/shared/models/system/SystemModel';
import { assertIsDefined } from '~/types/assert-type';

export type SystemLoaderData = {
  systems?: ListSystemsResponse;
} | null;

export const useOptionalSystemsResponse = () => {
  const initialData = useLoaderData() as SystemLoaderData;

  return useQuery({
    ...listSystemsQuery,
    initialData: initialData?.systems,
  });
};

export const useOptionalSystems = (): SystemModel[] | undefined => {
  const { data: systems } = useOptionalSystemsResponse();
  const issues = useOpenIssuesQuery();

  return useMemo(
    () => systems && new ListSystemsModel(systems, issues).systems,
    [systems, issues],
  );
};
export const useRequiredSystems = (): SystemModel[] => {
  const systems = useOptionalSystems();
  assertIsDefined(systems);
  return systems;
};
