import { Code } from '@connectrpc/connect';
import {
  Profile,
  Profile_EmailNotifications,
  User,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { isApiError } from '~/services/api/api-utils';
import { aqoraClient } from '~/services/api/aqora-client';

export const getAuthUser = async (): Promise<User | null> => {
  try {
    return await aqoraClient.getUser({
      name: 'users/me',
    });
  } catch (error) {
    if (
      isApiError(error) &&
      (error.code === Code.PermissionDenied ||
        error.code === Code.Unauthenticated)
    ) {
      return null;
    }
    throw error;
  }
};

export type ApiProfile = {
  displayName: string;
  languageCode: string;
  emailNotifications: Profile_EmailNotifications;
  lastAcceptedTermsAndConditions?: number;
};

export const updateAuthUserProfile = async ({
  profile,
}: {
  profile: Partial<ApiProfile>;
}): Promise<User> =>
  aqoraClient.updateUserProfile({
    name: 'users/me',
    profile: new Profile({
      displayName: profile.displayName,
      languageCode: profile.languageCode,
      emailNotifications: profile.emailNotifications,
      lastAcceptedTermsAndConditions: profile.lastAcceptedTermsAndConditions
        ? BigInt(profile.lastAcceptedTermsAndConditions)
        : undefined,
    }),
  });
