import { Box, Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { createDashboardRoute } from '~/shared/models/create-routes';

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <Box py={8}>
      <Container maxWidth="lg">
        <Typography
          component="h1"
          variant="h4"
          color="inherit"
          fontWeight="bold"
          gutterBottom
        >
          {t('notFound.title')}
        </Typography>

        <Button
          component={RouterLink}
          variant="contained"
          to={createDashboardRoute()}
        >
          {t('notFound.backHomeButton')}
        </Button>
      </Container>
    </Box>
  );
}
