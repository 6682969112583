import {
  Metric,
  Metric_DataPoint,
  MetricType,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import { ColorType, DataGroupingApproximationValue } from 'highcharts';
import { TFunction } from 'i18next';
import {
  getUnit,
  roundNumericMetric,
} from '~/shared/models/metrics/metric-conversions';
import { getSensorValue } from '~/shared/models/metrics/metrics-utils';
import { assertIsDefined, assertUnreachable } from '~/types/assert-type';

type DataPoint = [number, number | null];

function mapDataPoint(
  dataPoint: Metric_DataPoint,
  isNegative: boolean,
): DataPoint {
  const { timestamp } = dataPoint;
  assertIsDefined(timestamp);

  const multiplier = isNegative ? -1 : 1;

  return [timestamp.toDate().getTime(), multiplier * getSensorValue(dataPoint)];
}

type ChartTooltipProps = {
  dataPoint: number | undefined;
  t: TFunction;
};

export type ReservoirDynamicInfoType =
  | 'predictedWaterHeight'
  | 'ruleWaterHeight'
  | 'forecastTemperature'
  | 'forecastPrecipitation'
  | 'receivingOtherReservoirs'
  | 'receivingTap'
  | 'dumpingOtherReservoirs'
  | 'dumpingSewer';

export class ReservoirDynamicInfoModel {
  readonly chartData: DataPoint[] = [];

  readonly type: MetricType;

  readonly infoType: ReservoirDynamicInfoType;

  readonly color: ColorType;

  readonly unit: string | undefined;

  readonly chartType: 'column' | 'line' | 'area';

  readonly lineType: 'Solid' | 'Dash';

  readonly dataApproximation: DataGroupingApproximationValue;

  readonly axisId: string;

  constructor(
    metric: Metric,
    infoType: ReservoirDynamicInfoType,
    color: string,
  ) {
    this.type = metric.type;

    this.infoType = infoType;

    this.color = color;

    const isNegative =
      infoType === 'dumpingOtherReservoirs' || infoType === 'dumpingSewer';

    this.chartData = metric.dataPoints.map((dataPoint) =>
      mapDataPoint(dataPoint, isNegative),
    );

    this.unit = getUnit(this.type);

    this.chartType = this.getChartType();

    this.lineType = this.getLineType();

    this.dataApproximation = this.getDataApproximation();

    this.axisId = this.getAxisId();
  }

  private getChartType(): 'column' | 'line' | 'area' {
    switch (this.type) {
      case MetricType.PRECIPITATION_MM:
      case MetricType.WATER_HEIGHT_DIFFERENCE_MM:
        return 'column';
      case MetricType.TAP_ON:
        return 'area';
      default:
        return 'line';
    }
  }

  private getAxisId() {
    switch (this.infoType) {
      case 'forecastPrecipitation':
        return 'topPrecipitation';
      case 'forecastTemperature':
        return 'topTemperature';
      case 'predictedWaterHeight':
      case 'ruleWaterHeight':
        return 'topWaterHeight';
      case 'receivingOtherReservoirs':
      case 'receivingTap':
      case 'dumpingOtherReservoirs':
      case 'dumpingSewer':
        return 'bottom';

      default:
        return assertUnreachable(this.infoType);
    }
  }

  private getLineType(): 'Solid' | 'Dash' {
    switch (this.infoType) {
      case `ruleWaterHeight`:
        return 'Dash';
      default:
        return 'Solid';
    }
  }

  private getDataApproximation(): DataGroupingApproximationValue {
    switch (this.type) {
      case MetricType.WATER_HEIGHT_DIFFERENCE_MM:
      case MetricType.PRECIPITATION_MM:
        return 'sum';
      default:
        return 'average';
    }
  }

  private getName(t: TFunction) {
    switch (this.infoType) {
      case 'predictedWaterHeight':
        return t('dynamicInfoPage.chart.labels.predictedWaterHeight');
      case 'ruleWaterHeight':
        return t('dynamicInfoPage.chart.labels.ruleWaterHeight');
      case 'forecastTemperature':
        return t('dynamicInfoPage.chart.labels.forecastTemperature');
      case 'forecastPrecipitation':
        return t('dynamicInfoPage.chart.labels.forecastPrecipitation');
      case 'receivingOtherReservoirs':
        return t('dynamicInfoPage.chart.labels.receivingOtherReservoirs');
      case 'receivingTap':
        return t('dynamicInfoPage.chart.labels.receivingTap');
      case 'dumpingOtherReservoirs':
        return t('dynamicInfoPage.chart.labels.dumpingOtherReservoirs');
      case 'dumpingSewer':
        return t('dynamicInfoPage.chart.labels.dumpingSewer');
      default:
        return assertUnreachable(this.infoType);
    }
  }

  getChartTooltip({ dataPoint, t }: ChartTooltipProps) {
    assertIsDefined(dataPoint);
    const value = `${roundNumericMetric(this.type, dataPoint)} ${this.unit}`;
    return `
            <span>
              <span style="color:${this.color}">\u25CF</span>
                ${this.getName(t)}: <b>${value}</b>
            </span>`;
  }

  getChartLabel(t: TFunction) {
    const label = this.getName(t);
    return `<span style="color:${this.color}; font-size: 24px">\u25CF  <span style="color: #353750; font-size: 16px; line-height: 24px">${label}</span></span>`;
  }
}
