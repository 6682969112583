import { Tabs, Tab } from '@mui/material';
import { SystemPermission_Permission } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { useTranslation } from 'react-i18next';
import { useLocation, matchPath, Link } from 'react-router-dom';
import {
  DashboardIcon,
  DownloadIcon,
  NoteIcon,
  SystemIcon,
  UsersIcon,
} from '~/shared/components/Icons/Icons';
import { useHasPermissionForSystem } from '~/shared/hooks/auth-hooks';
import { useRequiredSystem } from '~/shared/hooks/system-hooks';
import {
  createRoutes,
  createDashboardRoute,
  createSystemRoute,
  createDownloadsRoute,
  createSystemUsersRoute,
  createSystemNotesRoute,
} from '~/shared/models/create-routes';

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export function SystemPageTabs() {
  const system = useRequiredSystem();
  const { t } = useTranslation();
  const { search } = useLocation();

  const canAccessUserManagement = useHasPermissionForSystem(
    SystemPermission_Permission.USER_MANAGEMENT,
    system.id,
  );

  const canSeeNotes = useHasPermissionForSystem(
    SystemPermission_Permission.ADMIN,
    system.id,
  );

  const routes = [
    {
      value: createRoutes.System,
      label: t('navigation.system'),
      to: createSystemRoute(system.id),
      icon: <SystemIcon />,
    },
    {
      value: createRoutes.Dashboard,
      label: t('navigation.dashboard'),
      to: createDashboardRoute(system.id),
      icon: <DashboardIcon />,
    },
    {
      value: createRoutes.Downloads,
      label: t('navigation.downloads'),
      to: createDownloadsRoute(system.id),
      icon: <DownloadIcon />,
    },
  ];

  if (canAccessUserManagement) {
    routes.push({
      value: createRoutes.SystemUsers,
      label: t('navigation.users'),
      to: createSystemUsersRoute(system.id),
      icon: <UsersIcon />,
    });
  }

  if (canSeeNotes) {
    routes.push({
      value: createRoutes.SystemNotes,
      label: t('navigation.systemNotes'),
      to: createSystemNotesRoute(system.id),
      icon: <NoteIcon />,
    });
  }

  const routeMatch = useRouteMatch(routes.map(({ value }) => value));
  const currentTab = routeMatch?.pattern.path ?? routes[0].value;

  return (
    <Tabs
      value={currentTab}
      sx={{
        '& .MuiTabs-indicator': {
          backgroundColor: 'secondary.main',
        },
        mb: 3,
        width: '100%',
        borderBottom: '1px solid',
        borderColor: 'divider',
      }}
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
    >
      {[...routes].map(({ value, label, to, icon }) => (
        <Tab
          key={value}
          label={label}
          value={value}
          to={`${to}${search}`}
          component={Link}
          icon={icon}
          iconPosition="start"
          sx={{
            minHeight: 48,
            fontSize: (theme) => theme.typography['body-s'],
            lineHeight: 1,
            '&.Mui-selected': {
              // fontWeight: 'bold',
              color: 'secondary.main',
            },
          }}
        />
      ))}
    </Tabs>
  );
}
