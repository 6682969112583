import { ActionFunction } from 'react-router-dom';
import { AuthError, AuthService } from '~/services/auth/AuthService';
import { queryClient } from '~/shared/api/query-client';
import { authUserQuery } from '~/shared/api/auth-user.queries';

const isWavinEmail = (email: string) => {
  const domain = email.split('@')[1];
  return domain === 'wavin.com' || domain === 'orbia.com';
};

export const hasValidWavinEmail = (
  submitEvent: React.FormEvent<HTMLFormElement>,
) => {
  const emailElement = submitEvent.currentTarget.elements.namedItem(
    'email',
  ) as HTMLInputElement;
  return isWavinEmail(emailElement.value);
};

export type SignInActionErrorData = AuthError | null;

export const signInAction =
  (auth: AuthService): ActionFunction =>
  async ({ request }): Promise<SignInActionErrorData> => {
    const formData = await request.formData();
    const { email, password } = Object.fromEntries(formData);

    if (typeof email !== 'string') {
      return new AuthError(
        'expected-email-to-be-a-text',
        'Expected email to be a text',
      );
    }

    if (isWavinEmail(email)) {
      const result = await auth.signInWithMicrosoft(email);
      await queryClient.invalidateQueries({
        queryKey: authUserQuery.queryKey,
      });
      return result;
    }

    if (typeof password !== 'string') {
      return new AuthError(
        'expected-password-to-be-a-text',
        'Expected password to be a text',
      );
    }
    const result = await auth.signIn(email, password);
    await queryClient.invalidateQueries({
      queryKey: authUserQuery.queryKey,
    });
    return result;
  };
